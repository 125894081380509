import PouchDB from 'pouchdb-browser';
import { IEvent } from './event';

export type TGender = 'M' | 'F' | 'O';
export const GENDER_VALUES = ['M', 'F', 'O'];
export const HAND_DOMI_VALUES = ['R', 'L', ''];
export const OGender = [
    { value: 'M', label: 'Male' },
    { value: 'F', label: 'Female' },
    { value: 'O', label: 'Other' },
];

export interface IAthlete {
    _id: string;
    _rev?: string;
    participant?: string;
    fullname: string;
    birthday?: string; // Phase out
    gender?: TGender;
    birthCountry?: string;
    competitorNumber?: string;
    ageCat?: string;
    // rifleCat?: string;
    specialCat?: string;
    repersentingCountry?: string;
    classification?: string;
    city?: string;
    state?: string;
    image?: any;
    residenceCountry?: string;
    profession?: string;
    hobbies?: string;
    maritalStatus?: 'Married' | 'Un Married' | '';
    handDominance?: 'L' | 'R' | '';
    noOfChildren?: number;
    education?: string;
    height?: number;
    weight?: number;
    email: string;
    divisions?: Array<string>;
    contactNumber?: string;
    doc_type: 'athlete';
    user_type: 'athlete' | 'admin' | 'club' | 'manual';
    matchUpdate?: string;
    timestamp?: Date;
    agree?: boolean;
    newsletter?: boolean;
    visibility?: boolean;
}

interface IAddNew {
    customOption?: boolean;
    fullname: string;
    division?: string;
    id?: string;
    nameFromProfile?: string;
}

export interface IMergeParticipantProps extends IAddNew, IAthlete {}

export const athleteDB = new PouchDB<IAthlete>(`${import.meta.env.VITE_DB_URL}/${import.meta.env.VITE_ATHLETE_DB}`, {
    skip_setup: true,
    auth: { username: import.meta.env.VITE_USN, password: import.meta.env.VITE_PSWD },
    fetch: function (url, opts) {
        return PouchDB.fetch(url, opts);
    },
});

export const findAthleteByEmail = async (email: string, userType: false | 'manual' | 'athlete' | 'club' = 'manual') => {
    await athleteDB.createIndex({
        index: { fields: ['email', 'doc_type', 'user_type'] },
    });
    const athletes = await athleteDB.find({
        selector: {
            doc_type: { $eq: 'athlete' },
            email: email,
            ...(userType && { user_type: userType }),
        },
    });
    return athletes.docs;
};

export const getAthleteById = async (id: string) => {
    return await athleteDB.get(id);
};

export const getUserParams = (allParticipants: Array<IAthlete | undefined> | undefined, event: IEvent | undefined, user: string) => {
    const selectedUser = allParticipants?.find((participant) => participant?._id === user);
    const selectedRequest = event?.participants?.find((parti) => parti.participant === user);
    return {
        competitorNumber: selectedRequest?.competitorNumber,
        fullname: selectedRequest?.fullname || selectedUser?.fullname,
        classification: selectedRequest?.classification,
        repersentingCountry: selectedRequest?.repersentingCountry,
        division: selectedRequest?.division,
    };
};

export const getGenderLabel = (value: string) => {
    return OGender.find((gender) => gender.value === value)!.label;
};
