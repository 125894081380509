import PouchDB from 'pouchdb-browser';

export const CLUB_ROLE = ['admin', 'manager'];
export type ECLUB_ROLE = 'admin' | 'manager' | string;

export interface IOwner {
    id: string;
    role: ECLUB_ROLE;
    name: string;
    email?: string;
    status: 'pending' | 'approved';
}

export enum ERangeUnit {
    YARDS = 'Y',
    METERS = 'M',
}

export interface IRange {
    name: string;
    distances: Array<number>;
    unit: ERangeUnit;
    location: string;
}

export interface IClubDivisions {
    divKey: string;
    divName: string;
}

export interface IClub {
    _id: string;
    _rev?: string;
    owners?: Array<IOwner>;
    fullname: string;
    clubName?: string;
    email: string;
    clubEmail?: string;
    contactNumber?: string;
    address?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    country?: string;
    ranges?: Array<IRange>;
    visibility?: boolean;
    newsletter?: boolean;
    agree?: boolean;
    doc_type: 'club' | string;
    facebook?: string;
    logo?: string;
    isStateClub?: boolean;
    isNationalClub?: boolean;
    isWorldClub?: boolean;
    clubDivisions?: Array<IClubDivisions>;
    timestamp?: Date;
}

export const clubDB = new PouchDB<IClub>(`${import.meta.env.VITE_DB_URL}/${import.meta.env.VITE_CLUB_DB}`, {
    skip_setup: true,
    auth: { username: import.meta.env.VITE_USN, password: import.meta.env.VITE_PSWD },
    fetch: function (url, opts) {
        return PouchDB.fetch(url, opts);
    },
});

export const findClubsByOwner = async (owner: string, isAdmin?: boolean) => {
    await clubDB?.createIndex({
        index: { fields: ['_id', 'owners'] },
    });
    const clubs = await clubDB?.find({
        selector: {
            _id: { $gt: null },
            ...(!isAdmin && {
                owners: {
                    $elemMatch: {
                        id: owner,
                    },
                },
            }),
        },
    });
    return clubs.docs;
};

export const getClubByID = async (id: string) => {
    const club = await clubDB.get(id);
    return club;
};

export const getAllClubs = async (ids?: Array<string>) => {
    const clubs = await clubDB.allDocs({ include_docs: true, ... (ids && { keys: ids }) });
    return clubs.rows.map(row => row.doc).filter(each => Boolean(each?.clubName));
};
