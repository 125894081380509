import { createSlice } from '@reduxjs/toolkit';
import { IClub } from '../db';

interface ClubStore {
    selectedClub: IClub;
}

const initialState: ClubStore = {
    selectedClub: JSON.parse(sessionStorage.getItem('selectedClub') || '{}') || {},
};

const clubSlice = createSlice({
    name: 'club',
    initialState: initialState,
    reducers: {
        setClub(state, { payload }) {
            sessionStorage.setItem('selectedClub', JSON.stringify(payload));
            state.selectedClub = payload;
        },
    },
});

export const { setClub } = clubSlice.actions;

export default clubSlice.reducer;
