import { wrapCreateBrowserRouter } from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';
import BlankLayout from '../components/Layouts/BlankLayout';
import DefaultLayout from '../components/Layouts/DefaultLayout';
import { AuthRequired } from './AuthRequired';
import { routes } from './routes';

const finalRoutes = routes.map((route) => {
    return {
        ...route,
        element:
            route.layout === 'blank' ? (
                <BlankLayout>{route.element}</BlankLayout>
            ) : route.protected ? (
                <AuthRequired>
                    <DefaultLayout>{route.element}</DefaultLayout>
                </AuthRequired>
            ) : (
                <DefaultLayout>{route.element}</DefaultLayout>
            ),
    };
});

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(finalRoutes);

export default router;
export * from './Modal';
