import { Title, TitleOrder } from '@mantine/core';
import { forwardRef } from 'react';

export type HeadingProps = {
    id: string;
    name: string;
    label: string;
    col: string;
    level: number;
    fullWidth?: boolean;
};

type Ref = HTMLDivElement;

export const Heading = forwardRef<Ref, HeadingProps>((props, ref) => {
    const { id, label = '', level, fullWidth = true, ...restProps } = props;
    const size = `h${level + 1}`;

    return (
        <Title order={level as TitleOrder} size={size}>
            {label}
        </Title>
    );
});
