export enum AppRoutes {
    DASHBOARD = '/',
    UPCOMING_EVENTS = '/events',
    UPCOMING_CLUB_EVENTS = '/events/:club_id',
    PAST_EVENTS = '/past_events',
    YOUR_EVENTS = '/your_events',
    ALL_RECORDS = '/records',
    YOUR_CLUBS = '/club/your_clubs',
    RECORDS = '/club/records',
    CLUB = '/club/',
    CLUB_DIVISIONS = '/club_divisions',
    CLUB_DETAILS = '/club/:id',
    CLUB_EVENTS = '/club/club_events',
    CLUB_CALENDAR = '/club/club_calendar',
    CLUB_TODO = '/club/todos',
    EVENT_DETAILS = '/:event_id',
    CREATE_EVENT = '/event/create',
    MATCHES = '/event/matches',
    CREATE_AGGS = '/event/create_aggs',
    GROUPS = '/event/groups',
    CATEGORIES = '/event/categories',
    AGGS = '/event/aggs',
    REQUESTS = '/event/requests',
    SQUADDING = '/event/squadding',
    SQUAD_ADJUST = '/event/squad_adjust',
    CREATE_TEAM = '/event/create_team',
    BUILD_FORM = '/event/build_form',
    LIST_PARTICIPANTS = '/list_participants',
    LIST_TEAMS = '/event/list_teams',
    ENTER_SCORE = '/event/enter_score',
    REGISTER = '/event/register',
    REGISTER_PATH = '/event/register/:event_id',
    ADD_PARTICIPANT = '/event/add_participant',
    IMPORT = '/event/import_scores',
    CREATE_CLUB = '/create_club',
    CREATE_ATHLETE = '/create_athlete',
    RESULTS = '/results',
    CLUB_RESULTS = '/results/:club_id',
    CLUB_EVENT_RESULTS = '/results/:club_id/:event_id',
    USER_PROFILE = '/auth/profile',
    ATHLETES = '/athlete',
    ATHLETE_PROFILE = '/athlete/:user_id',
    LOGIN = '/login',
    PASSWORD_RESET = '/password-reset',
    SIGNUP = '/signup',
    COMPLETE_PROFILE = '/signup/:user_id',
    PRIVACY = '/privacy',
    TERMS_AND_CONDITIONS = '/terms_and_conditions',
    FAQ = '/faq',
    DOCUMENTATION = '/documentation',
    SIGN_OUT = '/signout',
    LOCKSCREEN = '/auth/lockscreen',
    CLUB_LISTING = '/all_clubs',
    EXPORT_LABELS = '/export_labels'
}
