import { createSlice } from '@reduxjs/toolkit';
import { IEvent } from '../db/Couch/event';

interface EventStore {
    selectedEvent: IEvent;
}

const initialState: EventStore = {
    selectedEvent: JSON.parse(sessionStorage.getItem('selectedEvent') || "{}") || {},
};

const eventSlice = createSlice
({
    name: 'event',
    initialState: initialState,
    reducers: {
        setEvent(state, { payload }) {
            sessionStorage.setItem('selectedEvent', JSON.stringify(payload));
            state.selectedEvent = payload;
        },
    },
});

export const { setEvent } = eventSlice.actions;

export default eventSlice.reducer;
