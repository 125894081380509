import { lazyWithRetry } from '../utils/lazyWithRetry';
import { AppRoutes } from './Modal';
const ClubDivisions = lazyWithRetry(() => import('../pages/ManageClub/ClubDivisions'));
const Requests = lazyWithRetry(() => import('../pages/Event/Requests'));
const CustomRegister = lazyWithRetry(() => import('../pages/Event/CustomRegister'));
const AdjustSquadding = lazyWithRetry(() => import('../pages/Squadding/AdjustSquadding'));
const Error404 = lazyWithRetry(() => import('../pages/ErrorPages/Error404'));
const Login = lazyWithRetry(() => import('../pages/Authentication/Login'));
const Todolist = lazyWithRetry(() => import('../pages/Todolist'));
const RecoverId = lazyWithRetry(() => import('../pages/Authentication/RecoverId'));
const SignUp = lazyWithRetry(() => import('../pages/Authentication/SignUp'));
const Unlock = lazyWithRetry(() => import('../pages/Authentication/Unlock'));
const Dashboard = lazyWithRetry(() => import('../pages/Dashboard'));
const Results = lazyWithRetry(() => import('../pages/Results'));
const Events = lazyWithRetry(() => import('../pages/Events'));
const EventDetails = lazyWithRetry(() => import('../pages/EventDetails'));
const CreateClub = lazyWithRetry(() => import('../pages/CreateClub'));
const CreateAthlete = lazyWithRetry(() => import('../pages/CreateAthlete'));
const EnterScores = lazyWithRetry(() => import('../pages/EnterScores'));
const AddParticipant = lazyWithRetry(() => import('../pages/AddParticipant'));
const ListParticipants = lazyWithRetry(() => import('../pages/ListParticipants'));
const CreateTeam = lazyWithRetry(() => import('../pages/CreateTeam'));
const ListTeam = lazyWithRetry(() => import('../pages/ListTeam'));
const Matches = lazyWithRetry(() => import('../pages/Matches'));
const Groups = lazyWithRetry(() => import('../pages/Groups'));
const Categories = lazyWithRetry(() => import('../pages/Categories'));
const Aggs = lazyWithRetry(() => import('../pages/Aggs'));
const ClubEvents = lazyWithRetry(() => import('../pages/ClubEvents'));
const CreateEvent = lazyWithRetry(() => import('../pages/CreateEvent'));
const ClubCalendar = lazyWithRetry(() => import('../pages/ClubCalendar'));
const Squadding = lazyWithRetry(() => import('../pages/Squadding/Squadding'));
const ClubRecords = lazyWithRetry(() => import('../pages/ClubRecords'));
const YourClubs = lazyWithRetry(() => import('../pages/YourClubs'));
const Documentation = lazyWithRetry(() => import('../pages/Documentation'));
const UserProfile = lazyWithRetry(() => import('../pages/UserProfile'));
const ClubProfile = lazyWithRetry(() => import('../pages/ClubProfile'));
const AllClubs = lazyWithRetry(() => import('../pages/AllClubs'));
const PastEvents = lazyWithRetry(() => import('../pages/PastEvents'));
const Records = lazyWithRetry(() => import('../pages/Records'));
const AthleteProfile = lazyWithRetry(() => import('../pages/AthleteProfile'));
const BuildForm = lazyWithRetry(() => import('../pages/Event/BuildForm'));
const ImportScores = lazyWithRetry(() => import('../pages/Event/Scores/ImportScores'));

const routes = [
    // dashboard
    {
        path: AppRoutes.DASHBOARD,
        element: <Dashboard />,
        layout: 'Dashboard',
        protected: false,
    },
    {
        path: AppRoutes.RESULTS,
        element: <Results />,
        layout: 'default',
        protected: false,
    },
    {
        path: AppRoutes.CLUB_RESULTS,
        element: <Results />,
        layout: 'default',
        protected: false,
    },
    {
        path: AppRoutes.CLUB_EVENT_RESULTS,
        element: <Results />,
        layout: 'default',
        protected: false,
    },
    {
        path: AppRoutes.EVENT_DETAILS,
        element: <EventDetails />,
        layout: 'default',
        protected: false,
    },
    {
        path: AppRoutes.REQUESTS,
        element: <Requests />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.UPCOMING_EVENTS,
        element: <Events />,
        layout: 'default',
        protected: false,
    },
    {
        path: AppRoutes.UPCOMING_CLUB_EVENTS,
        element: <Events />,
        layout: 'default',
        protected: false,
    },
    {
        path: AppRoutes.CREATE_CLUB,
        element: <CreateClub />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.CREATE_ATHLETE,
        element: <CreateAthlete />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.ENTER_SCORE,
        element: <EnterScores />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.IMPORT,
        element: <ImportScores />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.ADD_PARTICIPANT,
        element: <AddParticipant />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.LIST_PARTICIPANTS,
        element: <ListParticipants />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.CREATE_TEAM,
        element: <CreateTeam />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.LIST_TEAMS,
        element: <ListTeam />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.MATCHES,
        element: <Matches />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.GROUPS,
        element: <Groups />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.CATEGORIES,
        element: <Categories />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.AGGS,
        element: <Aggs />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.CLUB_EVENTS,
        element: <ClubEvents />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.CREATE_EVENT,
        element: <CreateEvent />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.SQUADDING,
        element: <Squadding />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.SQUAD_ADJUST,
        element: <AdjustSquadding />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.RECORDS,
        element: <ClubRecords />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.CLUB_DIVISIONS,
        element: <ClubDivisions />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.CLUB_CALENDAR,
        element: <ClubCalendar />,
        layout: 'default',
        protected: false,
    },
    {
        path: AppRoutes.YOUR_CLUBS,
        element: <YourClubs />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.USER_PROFILE,
        element: <UserProfile />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.YOUR_CLUBS,
        element: <YourClubs />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.CLUB_TODO,
        element: <Todolist />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.BUILD_FORM,
        element: <BuildForm />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.DOCUMENTATION,
        element: <Documentation />,
        layout: 'default',
        protected: false,
    },
    // Authentication and User
    {
        path: AppRoutes.LOGIN,
        element: <Login />,
        layout: 'blank',
        protected: false,
    },
    {
        path: AppRoutes.SIGNUP,
        element: <SignUp />,
        layout: 'blank',
        protected: false,
    },
    {
        path: AppRoutes.COMPLETE_PROFILE,
        element: <SignUp />,
        layout: 'blank',
        protected: false,
    },
    {
        path: AppRoutes.LOCKSCREEN,
        element: <Unlock />,
        layout: 'blank',
        protected: true,
    },
    {
        path: AppRoutes.PASSWORD_RESET,
        element: <RecoverId />,
        layout: 'blank',
        protected: false,
    },
    {
        path: AppRoutes.CLUB_LISTING,
        element: <AllClubs />,
        layout: 'default',
        protected: false,
    },
    {
        path: AppRoutes.PAST_EVENTS,
        element: <PastEvents />,
        layout: 'default',
        protected: false,
    },
    {
        path: AppRoutes.ALL_RECORDS,
        element: <Records />,
        layout: 'default',
        protected: true,
    },
    {
        path: AppRoutes.ATHLETES,
        element: <AthleteProfile />,
        layout: 'default',
        protected: false,
    },
    {
        path: AppRoutes.ATHLETE_PROFILE,
        element: <AthleteProfile />,
        layout: 'default',
        protected: false,
    },
    {
        path: AppRoutes.REGISTER_PATH,
        element: <CustomRegister />,
        layout: 'default',
        protected: false,
    },
    {
        path: AppRoutes.CLUB_DETAILS,
        element: <ClubProfile />,
        layout: 'default',
        protected: false,
    },
    {
        path: '*',
        element: <Error404 />,
        layout: 'blank',
        protected: false,
    },
];

export { routes };

