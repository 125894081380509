import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../db';
import { AppRoutes } from '../router';
import { setUser } from '../store/userReducer';
import { setEvent } from "../store/eventReducer";
import { setClub } from "../store/clubReducer";

export const useLogout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        logout()
            .then((response) => {
                dispatch(setUser({}));
                dispatch(setEvent({}));
                dispatch(setClub({}));
                navigate(AppRoutes.LOGIN);
            })
            .catch((e) => console.error(e));
    };

    return handleLogout;
};
