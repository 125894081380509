import PouchDB from 'pouchdb-browser';
import { EAgeCat } from '../Models/age';
import { IEvent } from './event';

export interface IRequest {
    _id?: string;
    _rev?: string;
    athlete: string;
    fullname: string;
    event: string;
    classification?: string;
    repersentingCountry: string;
    division: string;
    ageCat?: EAgeCat;
    timestamp: number;
    doc_type: 'requests';
    status: 'Approved' | 'Pending' | 'Rejected';
}

export const requestDB = new PouchDB<IRequest>(`${import.meta.env.VITE_DB_URL}/${import.meta.env.VITE_REQUESTS_DB}`, {
    skip_setup: true,
    auth: { username: import.meta.env.VITE_USN, password: import.meta.env.VITE_PSWD },
    fetch: function (url, opts) {
        return PouchDB.fetch(url, opts);
    },
});

export const fetchRequests = async (events: Array<IEvent>) => {
    if (events && events.length > 0) {
        await requestDB.createIndex({
            index: { fields: ['event', 'status'] },
        });

        return await requestDB.find({
            selector: {
                $or: events.map((event) => {
                    return {
                        event: event._id,
                        status: 'Pending',
                    };
                }),
            },
            limit: 100
        });
    }
};

export const removeIt = async (doc: any, ownerEvents: Array<IEvent>, setRequests: Function) => {
    const removed = await requestDB.remove(doc);
    if (removed.ok) {
        await fetchRequests(ownerEvents).then((requests) => {
            requests && setRequests(requests.docs);
        });
    }
};
