import { combineReducers, configureStore } from '@reduxjs/toolkit';
import clubSlice from './clubReducer';
import eventSlice from './eventReducer';
import themeConfigSlice from './themeConfigSlice';
import userSlice from './userReducer';

const rootReducer = combineReducers({
    themeConfig: themeConfigSlice,
    user: userSlice,
    event: eventSlice,
    club: clubSlice,
});

export default configureStore({
    reducer: rootReducer,
});

export type IRootState = ReturnType<typeof rootReducer>;
