import Swal, { SweetAlertOptions } from 'sweetalert2';

interface IToast {
    cb?: Function;
}

export const toast = ({ title, cb, position = 'top', icon = 'success', timer = 3000 }: SweetAlertOptions & IToast) => {
    const toast = Swal.mixin({
        toast: true,
        position: position,
        showConfirmButton: false,
        timer: timer,
    });
    toast
        .fire({
            icon: icon,
            title: title,
            padding: '10px 20px',
        })
        .then(() => {
            cb && cb();
        });
};
