import classNames from 'classnames';
import { ChangeEvent, MouseEvent, forwardRef, useEffect, useState, type InputHTMLAttributes } from 'react';

export type PriceCheckboxProps = {
    id: string;
    label?: string | null;
    inline?: boolean;
    message?: string | false;
    error?: boolean;
    disabled?: boolean;
    size?: 'small' | 'medium' | 'large';
    amount: number;
    setTotal: Function;
    total: number;
    setTotalWithoutQty: Function;
    totalWithoutQty: number;
    calculate: Function;
    setFieldValue: Function;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>;

type Ref = HTMLInputElement;

export const PriceCheckbox = forwardRef<Ref, PriceCheckboxProps>((props, ref) => {
    const {
        id,
        name,
        label = null,
        inline = false,
        message = false,
        error = false,
        disabled = false,
        size = 'medium',
        className,
        amount,
        setTotal,
        setTotalWithoutQty,
        total,
        totalWithoutQty,
        multiple,
        calculate,
        setFieldValue,
        ...restProps
    } = props;
    const [qty, setQty] = useState<'' | number>(0);

    const handleQtyChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
            setQty('');
            setFieldValue(`qty_${name}`, '');
        } else if (e.target.value >= '0') {
            setQty(parseInt(e.target.value));
            setFieldValue(`qty_${name}`, e.target.value);
        } else {
            setQty(1);
            setFieldValue(`qty_${name}`, '1');
        }
    };

    useEffect(() => calculate(), [qty]);

    const handlePriceCheck = (e: MouseEvent<HTMLInputElement>) => {
        const { checked } = e.target as HTMLInputElement;
        if (checked) {
            setQty(1);
            !multiple && setTotalWithoutQty(totalWithoutQty + amount);
        } else {
            setQty(0);
            !multiple && setTotalWithoutQty(totalWithoutQty - amount);
        }
    };

    const styles = {
        small: 'p-2 sm:text-xs',
        medium: 'p-2.5 text-sm',
        large: 'p-4 sm:text-md',
    };

    const input = (
        <input
            ref={ref}
            type="checkbox"
            id={id}
            name={name}
            disabled={disabled}
            onClick={(e) => handlePriceCheck(e)}
            className={classNames('form-checkbox border-black rounded focus:border-blue-500 mr-2 dark:border-slate-300', { 'inline-block': inline, 'bg-gray-100': disabled }, styles[size], className)}
            {...restProps}
        />
    );

    const quantity = (
        <div className="ms-3">
            <b>Quantity</b>{' '}
            <input type="number" id={`tsqty_${name}`} data-amount={amount} className="qty form-input focus:border-primary-500 max-w-[70px] ms-1" onChange={(e) => handleQtyChange(e)} value={qty} />
        </div>
    );

    return (
        <>
            {label != null ? (
                <label htmlFor={id} className={classNames('mb-2 text-sm font-medium text-gray-800 dark:text-slate-300 flex-col md:flex-row md:items-center', inline ? 'inline-flex mr-2' : 'flex')}>
                    <div>
                        {input}
                        {label} - ${amount}
                    </div>
                    {props.required && '*'}
                    {multiple && <div>{quantity}</div>}
                </label>
            ) : (
                <>
                    {input} - <div>${amount}</div> {props.required && '*'} {multiple && <div>{quantity}</div>}
                </>
            )}
            {Boolean(message) && (
                <span
                    className={classNames('mb-2 block text-sm font-medium dark:border-slate-300', {
                        'text-red-700': error,
                    })}
                >
                    {message}
                </span>
            )}
        </>
    );
});

PriceCheckbox.displayName = 'PriceCheckbox';
