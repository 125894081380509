import Swal, { SweetAlertOptions } from 'sweetalert2';

interface IPopup {
    cb?: Function;
    onConfirm?: Function;
    onDenied?: Function;
}

export const showAlert = async (params: SweetAlertOptions & IPopup) => {
    const {
        cb,
        onConfirm,
        onDenied,
        icon = 'success',
        showCloseButton = true,
        timerProgressBar = true,
        showCancelButton = false,
        focusConfirm = false,
        padding = '2rem',
        customClass = `sweet-alerts-${icon}`,
    } = params;
    Swal.fire({
        icon,
        showCloseButton,
        timerProgressBar,
        showCancelButton,
        focusConfirm,
        padding,
        customClass,
        ...params,
    }).then((result) => {
        cb?.();
        if (result.isConfirmed) {
            onConfirm?.();
        } else if (result.isDenied) {
            onDenied?.();
        }
    });
};
