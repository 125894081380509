import { FieldInputProps, FieldProps } from 'formik';
import React, { useMemo } from 'react';
import Select, { OptionsOrGroups, Props } from 'react-select';
import countryList from 'react-select-country-list';

interface Custom {
    hasError: boolean;
    countries?: boolean;
}

const byPriority = (list: Array<{ value: string; label: string }>, priority: Array<string>) => {
    return list.sort((a, b) => (priority.includes(a.value) ? -1 : 1));
};

export const SelectField: React.FC<Props<any> & FieldProps & Custom> = ({ options, placeholder, className, field, isMulti = false, form, hasError, countries, ...rest }) => {
    const list = useMemo(() => byPriority(countryList().getData(), ['CA', 'US', 'GB', 'JA', 'AG', 'TT', 'AU', 'ZA', 'GY', 'KE', 'GE', 'NZ', 'IT']), []);

    const getValue = () => {
        const availableOptions: OptionsOrGroups<any, any> | undefined = countries ? list : options;
        if (availableOptions) {
            return isMulti ? availableOptions.filter((option) => field.value?.indexOf(option.value) >= 0) : field.value ? availableOptions.find((option) => option.value === field.value) : "";
        } else {
            return isMulti ? [] : '';
        }
    };

    return (
        <Select
            id={field.name}
            options={countries ? list : options}
            placeholder={placeholder}
            className={className}
            isMulti={isMulti}
            classNames={{
                control: (state) => (hasError ? 'border-2 !border-rose-600 !bg-rose-50' : 'border-grey-300'),
                placeholder: (state) => (hasError ? '!text-danger' : 'border-grey-300'),
                dropdownIndicator: () => '!text-[#808080]',
            }}
            name={field.name}
            value={getValue()}
            onChange={(option: any) => form.setFieldValue(field.name, isMulti ? option.map((o: FieldInputProps<any>) => o.value) : option.value)}
            onBlur={field.onBlur}
            {...rest}
        />
    );
};
