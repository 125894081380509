import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useErrorAlerts } from '../components';
import { fetchSession } from '../db';
import { AppRoutes } from './Modal';

interface Props {
    children: JSX.Element;
}

export const AuthRequired: React.FC<Props> = ({ children }) => {
    const checkAuth = async () => await fetchSession();
    const [isLoggedIn, setLoggedIn] = useState<boolean>(true);
    const handleError = useErrorAlerts();
    const { pathname } = useLocation();

    useEffect(() => {
        checkAuth()
            .then((res) => setLoggedIn(res.userCtx.name))
            .catch((e) => handleError(e, 'auth_required'));
    }, [pathname]);

    return isLoggedIn ? children : <Navigate to={AppRoutes.LOGIN} replace={true} />;
};
