import PouchDB from 'pouchdb-browser';
import { EMatchType } from './event';

export interface IResults {
    event: string;
    eventName: string;
    club: string;
    clubName?: string;
    doc_type: 'results' | string;
    results: Array<IResult>;
    timestamp: number;
    hidden?: boolean;
    _id?: string;
    _rev?: string;
}

export interface IResult {
    matchType: EMatchType;
    matchId: string;
    match: string;
    distance: number;
    participant: string;
    shots: Array<string> | Array<Array<string>>;
    total: string;
    best?: boolean;
}

export enum EAggs {
    GRAND = 'GrandAgg',
    DAY = 'DayAgg',
    CLASS = 'ClassAgg',
    COUNTRY = 'CountryAgg',
    GENDER = 'Gender',
    AGE = 'Age',
    DISTANCE = 'Distance',
    MATCH_GROUP = 'MatchGroup',
    MULTI_GROUP = 'MULTI_Group',
    TEAM_MATCHES = 'TeamMatches',
    DIVSION = 'Division',
}

export interface ITeamResults {
    teamId: string;
    results: Array<ITeamResult>;
}

export interface ITeamResult {
    shooter: string;
    result: Array<IResult>;
}

export const resultDB = new PouchDB<IResults>(`${import.meta.env.VITE_DB_URL}/${import.meta.env.VITE_RESULTS_DB}`, {
    skip_setup: true,
    auth: { username: import.meta.env.VITE_USN, password: import.meta.env.VITE_PSWD },
    fetch: function (url, opts) {
        return PouchDB.fetch(url, opts);
    },
});

export const findResultsByEvent = async (eventId: string | undefined) => {
    if (eventId) {
        //   resultDB.createIndex({
        //     index: { fields: ["doc_type", "championship"] },
        //   });
        const results = await resultDB.find({
            selector: {
                event: { $eq: eventId },
            },
        });
        return results.docs?.length > 0 ? results.docs : [];
    }
};
