import PouchDB from 'pouchdb-browser';

export enum ERangeUnit {
    YARDS = 'Y',
    METERS = 'M',
}

interface IPastHolders {
    name: string;
    date: Date;
    place: string;
    score: string;
}

export interface IRecord {
    distance: number;
    unit: ERangeUnit;
    shots: number;
    score: string;
    division: string;
    name: string;
    date: Date;
    place: string;
    override?: boolean;
    pastHolders?: Array<IPastHolders>;
}

export interface IRecordDoc {
    _id: string;
    _rev?: string;
    isStateRecord: boolean;
    stateRecordId: string;
    stateName: string;
    isNationalRecord: boolean;
    nationalRecordId: string;
    nationName: string;
    isWorldRecord: boolean;
    worldRecordId: string;
    records: Array<IRecord>;
    lastUpdated: Date;
}

export const recordDB = new PouchDB<IRecordDoc>(`${import.meta.env.VITE_DB_URL}/${import.meta.env.VITE_RECORD_DB}`, {
    skip_setup: true,
    auth: { username: import.meta.env.VITE_USN, password: import.meta.env.VITE_PSWD },
    fetch: function (url, opts) {
        return PouchDB.fetch(url, opts);
    },
});

export const getRecordsByID = async (id: string) => {
    const records = await recordDB.get(id);
    return records;
};
