import { createSlice } from '@reduxjs/toolkit';
import { IUserCTX } from '../db';

interface UserStore {
  user: IUserCTX;
}

const initialState: UserStore = {
    user: JSON.parse(localStorage.getItem('user') || "{}") || {},
};

const userSlice = createSlice
({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUser(state, { payload }) {
            localStorage.setItem('user', JSON.stringify(payload));
            state.user = payload;
        },
    },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
