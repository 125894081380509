import { HEADERS } from './headers';

interface ICouchUserMetadata {
    id: string;
    fullName: string;
    clubs?: Array<string>;
}

interface ILogin {
    name: string;
    password: string;
}

interface IUserNotify {
    event: string;
    parti: string;
    status: string;
    enabled: boolean;
}

export interface IUserCTX {
    name: string;
    roles: Array<'admin' | 'club' | 'athlete' | 'public' | 'manager'>;
    metadata?: ICouchUserMetadata;
}

export interface IUserRequest {
    name: string;
    password?: string;
    roles?: Array<string>;
    metadata?: ICouchUserMetadata;
}

export interface IUserError {
    error: string;
    reason: string;
}

export interface IResetPass {
    email: string;
    token: string;
    password: string;
}

export const getUserData = async (name: string) => {
    const response = await fetch(import.meta.env.VITE_HOST + 'user/get?name=' + name);
    return response.json();
};

export const modifyUser = async (data: IUserRequest) => {
    const response = await fetch(import.meta.env.VITE_HOST + 'user/modify', {
        ...HEADERS,
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json();
};

export const signUpUser = async (data: IUserRequest) => {
    const response = await fetch(import.meta.env.VITE_HOST + 'user/signup', {
        ...HEADERS,
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json();
};

export const login = async (data: ILogin) => {
    const response = await fetch(import.meta.env.VITE_DB_URL + '_session', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    return response.json();
};

export const logout = async () => {
    const response = await fetch(import.meta.env.VITE_DB_URL + '_session', {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });
    return response.json();
};

export const sendResetLink = async (email: string) => {
    const response = await fetch(import.meta.env.VITE_HOST + 'user/reset', {
        ...HEADERS,
        body: JSON.stringify({ email }),
    });
    return response.json();
};

export const changePassword = async (data: IResetPass) => {
    const response = await fetch(import.meta.env.VITE_HOST + 'user/reset', {
        ...HEADERS,
        method: 'PUT',
        body: JSON.stringify(data),
    });
    return response.json();
};

export const fetchSession = async () => {
    const response = await fetch(import.meta.env.VITE_DB_URL + '_session', {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });
    const data = await response.json();
    if (data.userCtx?.name) {
        return data;
    } else {
        throw new Object({ status: 403, message: "User not logged in or session timed out." });
    }
};

export const notifyUser = async (data: IUserNotify) => {
    const response = await fetch(import.meta.env.VITE_HOST + 'user/notify', {
        ...HEADERS,
        body: JSON.stringify(data),
    });
    return response.json();
};
